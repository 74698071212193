.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.liquidationTwo .siteBanner {
    width: 100%;
    max-height: 550px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.liquidationTwo .siteBanner.multiple {
    height: 100%;
}

.liquidationTwo.primeBids .siteBanner.multiple {
    margin-bottom: 0;
}

.liquidationTwo .PmCategoryBase-root,
.LiquidationThree .PmCategoryBase-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding: 15px 0;
    margin-bottom: 15px;
}

.liquidationTwo .PmCategoryBase-root {
    margin-bottom: 50px;
}

.liquidationTwo .multisellerListCard.lotCard .lotBidInfo h6:first-child span {
    color: var(--secColor);
}

.liquidationTwo.primeBids .siteBanner {
    width: 100%;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.liquidationTwo.primeBids .siteBanner.multiple {
    width: 100%;
    max-height: 200px;
}

.liquidationTwo.primeBids .siteBanner .bannerContent {
    position: absolute;
    left: 60px;
    transform: translateY(-50%);
}

.liquidationTwo.primeBids .siteBanner .bannerContent h3 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.liquidationTwo.primeBids .siteBanner .bannerContent h2 {
    color: #000;
    font-size: 22px;
    font-weight: 800;
    line-height: 92.5%;
    padding-bottom: 20px;
}

.liquidationTwo.emursMck .siteBanner {
    width: 100%;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.liquidationTwo.emursMck .MuiButton-root.filterToggle {
    background: var(--secColor);
    color: var(--primColor);
}

.liquidationTwo.emursMck .siteBanner.multiple {
    width: 100%;
    max-height: 590px;
    border: 1px solid #c18c00;
    border-radius: 50px;
}

.liquidationTwo.emursMck .siteBanner.multiple img {
    max-height: 530px;
}

.liquidationTwo.emursMck .siteBanner .bannerContent {
    position: absolute;
    left: 0 !important;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: auto;
    max-width: fit-content;
}

.liquidationTwo.emursMck .siteBanner .bannerContent h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.liquidationTwo.emursMck .siteBanner .bannerContent h2 {
    font-size: 22px;
    font-weight: 800;
    line-height: 92.5%;
    padding-bottom: 20px;
}

.beachLiquidation table .badgeStatus {
    padding: 0px 0px 0px 50px;
}

.beachLiquidation .schedulePickBeach label span:last-child {
    display: none;
}

.liquidationTwo.emursMck .bid-wrapper.wInputField > div:first-child {
    width: 100%;
}

.beachLiquidation .schedulePickBeach label {
    justify-content: center;
}

.liquidationTwo .PmCategoryBase-root .PmCategoryList-root .PmCategoryImage-root {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    cursor: pointer;
    display: block;
    margin-bottom: 15px;
}

.LiquidationThree .PmCategoryBase-root .PmCategoryList-root .PmCategoryImage-root {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    cursor: pointer;
    display: block;
    margin-bottom: 15px;
}

.liquidationTwo .PmCategoryBase-root .PmCategoryList-root .PmCategoryImage-root .PmCategoryImage,
.LiquidationThree .PmCategoryBase-root .PmCategoryList-root .PmCategoryImage-root .PmCategoryImage {
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.liquidationTwo .PmCategoryBase-root .PmCategoryList-root .PmCategoryTitle {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
}

.LiquidationThree .PmCategoryBase-root .PmCategoryList-root .PmCategoryTitle {
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
}

.liquidationTwo .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.liquidationTwo.primeBids .siteBanner img {
    object-position: center;
}

.liquidationTwo .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.liquidationTwo .siteBanner .bannerContent {
    position: absolute;
    top: 80px;
    left: 60px;
    z-index: 999999999;
}

.liquidationTwo .siteBanner .bannerContent h2 {
    font-size: 34px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    line-height: 50px;
}

.liquidationTwo .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.liquidationTwo .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.liquidationTwo .searchContainer .searchLt {
    overflow-y: scroll;
    height: 95vh;
    padding-right: 10px;
    top: 10px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.BidAuction .sotingBtngrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(auto-fill, minmax(157px, 1fr)); */
    gap: 10px;
}

.BidAuction .searchRt .auctionSearchPaginationTop {
    margin: 20px !important;
}

.BidAuction .selectedSortOption,
.BidAuction .selectedCaterory {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}

.BidAuction .searchContainer .searchLt {
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 0;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.searchContainer .searchRt.expandMore {
    max-width: 100%;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.liquidationTwo .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.BidAuction .searchContainer .lotResults.Grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.liquidationTwo .card.buynow .gridItemAct,
.liquidationTwo .card .gridItemAct,
.liquidationTwo .unauth .card.buynow .timer-text .primButton {
    /* margin-top: 22px; */
}

.liquidationTwo .liquidationBuyCard.auth .mslMisc .biddingCnt button {
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.liquidationTwo .grid-card .hostedBy {
    min-height: 18px;
}

.liquidationTwo .grid-card .gcTimer {
    margin: 5px 0;
    margin-top: 10px;
    height: 24px;
    color: var(--primColor);
}

.liquidationTwo.primeBids .grid-card .gcTimer.primeTmCrd .timerCnt span:nth-child(2) {
    margin-right: 5px;
    word-spacing: 0;
}

.liquidationTwo.primeBids .grid-card .gcTimer.primeTmCrd .timerCnt span:nth-child(1) {
    display: none;
}

.liquidationTwo.primeBids .grid-card .gcTimer.primeTmCrd .timerCnt {
    font-weight: 700;
    font-size: 14px;
    word-spacing: 4px;
}

.liquidationTwo.primeBids .filterButtonMobile {
    position: fixed;
    bottom: 100px;
    height: 40px;
    left: 50%;
    min-width: unset;
    transform: translateX(-50%);
    z-index: 1000;
    width: 40%;
    background-color: #fefb41;
    color: #000;
    border-radius: 5px;
    display: none;
}

.liquidationTwo .grid-card .gcTimer .timerCnt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.liquidationTwo .auctionSearchPaginationTop .pagination {
    margin: 0;
}

.liquidationTwo.primeBids .card.grid-card .galleryPrice.rtlrPrice .prcLabel {
    display: none;
}

.liquidationTwo.primeBids .card.grid-card .galleryPrice.rtlrPrice .prcTxtVal {
    text-decoration: line-through;
}

.liquidationTwo.primeBids .multisellerListCard .galleryPrice.rtlrPrice .prcLabel {
    display: none;
}

.liquidationTwo.primeBids .multisellerListCard .galleryPrice.rtlrPrice .prcTxtVal {
    text-decoration: line-through;
}

/* Wizard Auctions */
.wizardTheme .siteBanner {
    max-height: 875px !important;
    background-image: url('/src/assets/images/wizardAuctions/Simplification.png');
    background-size: cover;
    height: 100% !important;
    padding: 25px 20px;
    min-height: 550px;
    border-radius: 30px !important;
}

.wizardTheme .siteBanner .grid-card .grid-img-wrap img {
    height: 250px !important;
}

.wizardTheme .siteBanner .grid-card .card-body img {
    height: auto;
    width: auto;
}

.wizardTheme .siteBanner .bannerContent {
    position: unset !important;
}

.wizardTheme .siteBanner .bannerContent h2 {
    color: #fff !important;
    font-weight: 700 !important;
    text-transform: capitalize;
}

.wizardTheme .siteBanner .bannerContent img {
    object-fit: contain;
}

.wizardTheme .siteBanner .bannerContent span,
.wizardTheme .siteBanner .bannerContent img {
    max-width: 600px;
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-position: center;
}

.wizardCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}

.wizardCards .howItCard {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
}

.wizardCards .howItCard div {
    gap: 5px;
}

.wizardCards .howItCard .quest {
    font-size: 20px;
    font-weight: 700;
    width: 200px;
    margin: 10px auto 30px;
}

.wizardCards .howItCard h2 {
    font-size: 24px;
    font-weight: 800;
}

.wizardCards .howItCard .wizardImg {
    max-height: 181px;
    max-width: 310px;
}

.wizardCards .howItCard .lgSgnBtn,
.wizardTheme .siteBanner .bannerContent .lgSgnBtn {
    border: 1px solid var(--secColor) !important;
    padding: 6px 30px;
    background-color: var(--secColor);
    font-size: 18px;
    font-weight: 700;
    border-radius: 8px;
}

.wizardCards .howItCard img {
    max-width: 140px;
    max-height: 100px;
    object-fit: contain !important;
    width: 100%;
}

.wizardCards .howItCard h3 {
    font-size: 18px;
    font-weight: 600;
}

.wizardCards .howItCard p {
    font-size: 14px;
}

.liquidationTwo.wizardTheme .customSelect select {
    color: var(--primColor);
    font-weight: 600;
}

.liquidationTwo.wizardTheme .searchLt .filterToggle span {
    font-size: 22px;
    font-weight: 600;
}

.liquidationTwo.wizardTheme .searchLt .filterToggle {
    margin-bottom: 25px;
}

/* Wizard Auctions */
/* Responsive Start */
@media (max-width: 1450px) {
    .liquidationTwo.emursMck .siteBanner.multiple {
        width: 100%;
        max-height: 530px;
        border: 1px solid #c18c00;
        border-radius: 50px;
    }

    .liquidationTwo.emursMck .siteBanner .bannerContent {
        top: 45%;
    }

    .liquidationTwo.emursMck .siteBanner.multiple img {
        max-height: 475px;
    }
}

@media (max-width: 1300px) {
    .liquidationTwo.emursMck .siteBanner.multiple {
        width: 100%;
        max-height: 530px;
        border: 1px solid #c18c00;
        border-radius: 50px;
    }

    .liquidationTwo.emursMck .siteBanner.multiple img {
        max-height: 400px;
    }
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }

    .liquidationTwo.emursMck .siteBanner.multiple {
        max-height: 400px;
    }

    .liquidationTwo.emursMck .siteBanner.multiple img {
        max-height: 350px;
    }
}

@media (max-width: 1024px) {
    .liquidationTwo .auctionSearchPaginationTop .pagination {
        margin: 10px 0 20px 0;
    }

    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }

    .liquidationTwo .mblStckyFilter {
        position: relative;
        background: white;
        z-index: 999;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        margin: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .liquidationTwo .mblStckyFilter .customSelect {
        margin-bottom: 0px;
    }

    .liquidationTwo .mblStckyFilter .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 33px;
    }

    .liquidationTwo .mblStckyFilter .filterButton {
        min-width: unset;
        height: 40px;
    }

    .liquidationTwo .mblStckyFilter .filterButton.srchBtn {
        color: var(--primColor);
    }
}

@media (max-width: 992px) {
    .liquidationTwo .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 15px;
    }

    .liquidationTwo.emursMck .siteBanner.multiple {
        max-height: 280px;
    }

    .liquidationTwo.emursMck .siteBanner.multiple img {
        max-height: 300px;
    }

    .liquidationTwo.emursMck .siteBanner .bannerContent {
        top: 40%;
    }

    .grid-card .grid-img-wrap img {
        height: 250px;
    }

    .liquidationTwo .grid-card .grid-img-wrap img {
        height: 200px;
    }

    .liquidationTwo.primeBids .siteBanner {
        width: 100%;
        max-height: 400px !important;
        height: 400px !important;
    }

    .liquidationTwo.primeBids .siteBanner .bannerContent {
        top: 160px !important;
    }
}

@media (max-width: 991px) {
    .liquidationTwo .multisellerListCard {
        flex-wrap: nowrap;
    }

    .liquidationTwo .multisellerListCard .mslDesc {
        width: 50% !important;
    }
}

@media only screen and (min-width: 610px) and (max-width: 991px) and (orientation: landscape) {
    .liquidationTwo .List .multisellerListCard {
        flex-wrap: nowrap;
    }

    .liquidationTwo .multisellerListCard .mslDesc {
        width: 50% !important;
    }

    .liquidationTwo .multisellerListCard .mslMisc {
        min-width: 32%;
    }

    .liquidationTwo .multisellerListCard .grid-img-wrap {
        width: 28%;
    }

    .liquidationTwo .multisellerListCard.lotCard .grid-img-wrap > span,
    .liquidationTwo .multisellerListCard .grid-img-wrap .lazy-load-image-background {
        width: 100% !important;
    }

    .liquidationTwo .multisellerListCard .mslDesc .listTitle {
        font-size: 14px;
    }

    .liquidationTwo .multiSellerTimerView h6 {
        font-size: 14px;
    }

    .liquidationTwo .multisellerListCard.lotCard .lotBidInfo h6 {
        font-size: 13px;
    }

    .liquidationTwo .subLotLine .slAct .biddingCnt .customInput {
        min-width: 50px;
    }

    .liquidationTwo .multisellerListCard.lotCard .lotBidInfo h6:first-child span {
        font-size: 15px;
    }

    .liquidationTwo .multiSellerTimerView h6:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 768px) {
    .liquidationTwo.primeBids .filterButtonMobile {
        display: block;
    }

    .liquidationTwo.primeBids .searchContainer.gridViewPrimebidsMobile {
        display: grid;
        gap: 15px;
        grid-template-columns: 1fr 1fr;
    }

    .beachLiquidation .bid-wrapper {
        flex-wrap: wrap;
    }

    .beachLiquidation .bid-wrapper .small.primButton {
        width: 100%;
        margin-top: 5px;
    }

    .liquidationTwo.primeBids
        .searchContainer.gridViewPrimebidsMobile
        .lotResults.liquidationBuyCard {
        height: 100%;
        display: grid;
        align-items: stretch;
    }

    .beachLiquidation .amount.mb-price-td {
        text-align: left;
    }
}

@media (max-width: 700px) {
    .liquidationTwo .auctionsSearch .gridListToggle {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .liquidationTwo.primeBids .siteBanner {
        width: 100%;
        max-height: 300px !important;
        height: 300px !important;
    }

    .liquidationTwo.primeBids .siteBanner img {
        object-fit: cover;
    }

    .liquidationTwo.primeBids .siteBanner .bannerContent {
        top: 160px !important;
    }

    .liquidationTwo.emursMck .siteBanner {
        width: 100%;
        max-height: 300px !important;
        height: 300px !important;
    }

    .liquidationTwo.emursMck .siteBanner img {
        object-fit: cover;
    }

    .liquidationTwo.emursMck .siteBanner .bannerContent {
        top: 160px !important;
    }

    .liquidationTwo.hyperMicro .gridListToggle,
    .liquidationTwo.hyperMicro .auctionsSearch .gridListToggle {
        width: 100%;
        text-align: right;
    }

    .liquidationTwo .mblStckyFilter {
        grid-template-columns: 1fr 1fr;
    }

    .liquidationTwo .subLotLine .biddingCnt div:first-child {
        margin-bottom: 0;
    }

    .liquidationTwo .auctionsSearch .card.grid-card {
        min-height: 100%;
    }

    .liquidationTwo .gridListToggle {
        /* display: none; */
    }

    .liquidationTwo .auctionsSearch .gridListToggle {
        text-align: right;
    }

    .liquidationTwo .auctionsSearch .auctionsMiscFilter .customSelect {
        width: calc(100% - 52%);
        margin: 10px auto;
    }

    .liquidationTwo.hyperMicro .auctionsSearch .auctionsMiscFilter .customSelect {
        width: calc(100% - 52%);
        margin: 10px auto;
    }

    .liquidationTwo .multisellerListCard {
        flex-wrap: wrap;
    }

    .liquidationTwo .multisellerListCard .mslDesc {
        width: 100% !important;
    }

    .liquidationTwo .searchContainer .lotResults.Grid {
        /* grid-template-columns: 1fr 1fr; */
        gap: 5px;
    }

    .liquidationTwo .searchContainer .lotResults.Grid .grid-card .card-body {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
    }

    .liquidationTwo .mblStckyFilter {
        gap: 5px;
    }

    .liquidationTwo .mblStckyFilter .customSelect {
        width: 100%;
    }

    .liquidationTwo .liquidationBuyCard .card.grid-card .grid-img-wrap img {
        height: 180px;
        object-fit: contain;
    }

    .liquidationTwo .grid-card .grid-notes {
        height: 180px;
    }

    .BidAuction .liquidationBuyCard .card.grid-card .grid-img-wrap img {
        height: 234px;
    }

    .liquidationTwo .liquidationBuyCard .card.grid-card,
    .liquidationTwo .liquidationAuctions .card.grid-card {
        padding: 5px;
    }

    .liquidationTwo .grid-card .gridItemAct .favoriteCheck {
        width: 30px;
    }

    .liquidationTwo .grid-card .gridItemAct .favoriteCheck label span {
        font-size: 22px;
    }

    .liquidationTwo .grid-card .gridItemAct {
        display: flex;
        align-items: center;
    }

    .liquidationTwo .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 12px;
    }

    .liquidationTwo .grid-card .gridItemAct .cartBtn .MuiButton-label .material-icons {
        font-size: 15px;
    }

    .liquidationTwo .grid-card .gridItemAct .cartBtn {
        height: 44px;
        margin: 0 5px;
        width: 41px;
        min-width: unset;
    }

    .liquidationTwo .grid-card .galleryPrice {
        font-size: 14px;
    }

    .liquidationTwo .grid-card .hostedBy {
        min-height: 18px;
        font-size: 13px;
    }

    .liquidationTwo .grid-card .gcTimer {
        margin: 5px 0px;
    }

    .liquidationTwo .mainHeader .nav-standard-top-area-search-inner {
        min-width: unset;
    }

    .liquidationTwo .mblStckyFilter .filterButton {
        min-width: unset;
        height: 40px;
    }

    .liquidationTwo
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .liquidationTwo
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100% !important;
    }

    .liquidationTwo .siteBanner .bannerContent {
        position: absolute;
        top: 10px;
        left: 15px;
    }

    .liquidationTwo .siteBanner .bannerContent h2 {
        font-size: 26px;
        line-height: 37px;
    }

    .liquidationTwo .siteBanner.multiple {
        height: 130px;
    }

    .liquidationTwo .searchRt .loadingCnt {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .liquidationTwo .searchRt .gridViewSkeleton {
        max-width: unset;
        height: unset;
    }

    .liquidationTwo .searchRt .gridViewSkeleton .skeletonWrapper {
        display: none;
        padding: 5px;
    }

    .liquidationTwo .searchRt .gridViewSkeleton .skeletonWrapper:first-child,
    .liquidationTwo .searchRt .gridViewSkeleton .skeletonWrapper:last-child {
        display: block;
    }
}

@media (max-width: 550px) {
    .liquidationTwo .siteBanner img {
        object-fit: contain;
    }

    .liquidationTwo .todayDealsTit {
        margin: 30px auto;
        font-size: 32px;
    }
}

@media (max-width: 500px) {
    .liquidationTwo .auctionsMiscFilter .customCheckbox {
        margin-right: auto;
    }

    .liquidationTwo .auctionsMiscFilter .customCheckbox .MuiListItem-root {
        padding: 0;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }

    .liquidationTwo .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .liquidationTwo .auctionsSearch .multiSellerTimerView h6:not(:last-child) {
        margin-right: 10px;
    }

    .liquidationTwo .auctionsSearch .searchResults.Grid .card-body .primButton button {
        padding: 6px 12px;
        font-size: 12px;
    }

    .timerWrapper .timeLabel::after,
    .timerWrapper .timeLabel::before {
        display: none;
    }
}

@media (max-width: 500px) {
    .liquidationTwo .liquidationBuyCard .card.grid-card,
    .liquidationTwo .liquidationAuctions .card.grid-card {
        min-height: fit-content;
    }

    .liquidationTwo .timerWrapper .timeLabel::before {
        left: 10%;
    }

    .liquidationTwo .timerWrapper .timeLabel::after {
        right: 10%;
    }

    .liquidationTwo .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }

    .liquidationTwo .card.grid-card .gpBidCart.spByNw {
        /* display: none; */
    }

    .liquidationTwo .card.grid-card .galleryPrice.rtlrPrice {
        display: flex;
    }

    .liquidationTwo.primeBids .card.grid-card .galleryPrice.rtlrPrice {
        justify-content: flex-end;
    }

    .liquidationTwo .auctionSearchPaginationTop .pagination .page-item .page-link {
        width: 35px;
        height: 35px;
        padding: 5px 5px;
    }
}

@media screen and (max-width: 428px) {
    .liquidationTwo .grid-card .gcTimer {
        height: 42px;
        font-size: 13px;
    }

    .liquidationTwo .grid-card .gcTimer .timerCnt {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 400px) {
    .liquidationTwo .grid-card .card-body .biddingCnt {
        grid-template-columns: 1fr;
    }

    .liquidationTwo .grid-card .gcTimer {
        margin: 0px 0px;
    }
}

@media screen and (max-width: 360px) {
    .liquidationTwo .grid-card .gcTimer .timerCnt {
        flex-direction: column;
        align-items: flex-start;
    }

    .liquidationTwo .grid-card .gcTimer {
        height: auto;
    }

    .liquidationTwo .grid-card .gcTimer .timerCnt .material-icons-outlined {
        display: none;
    }
}
