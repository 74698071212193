.multisellerListCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    box-shadow: 0px 3px 30px #eaeaea;
    padding: 10px;
}

.liquidationTwo.emursMck .multisellerListCard {
    box-shadow: 0 3px 30px #ffffff30;
    border-radius: 15px;
    background-color: #fff;
}
.liquidationTwo.emursMck .multisellerListCard h6.hostedBy {
    display: none;
}
.liquidationTwo.emursMck .multisellerListCard h6,
.liquidationTwo.emursMck .multisellerListCard span,
.liquidationTwo.emursMck .multisellerListCard p {
    color: var(--primColor) !important;
}

.LotsAuction .multisellerListCard {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    background-color: #ffffff;
}

.multisellerListCard .grid-img-wrap {
    height: 240px;
    width: 200px;
    position: relative;
}

.wizardAuctionCard {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
    box-shadow: none;
}

.wizardAuctionCard .wizard-image-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
}

.wizardAuctionCard .wizard-image-wrap img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.liquidationTwo.wizardTheme .multisellerListCard {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    box-shadow: none;
    position: relative;
    margin: 0;
    background-color: #fff;
}

.liquidationTwo.wizardTheme .multisellerListCard .grid-img-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    height: 250px;
    width: 100%;
    overflow: hidden;
}

.liquidationTwo.wizardTheme .multisellerListCard .grid-img-wrap span {
    max-height: none;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslMisc button {
    text-transform: none;
}

.liquidationTwo.wizardTheme .multisellerListCard .timeWrp {
    background-color: #fff;
    border-radius: 8px;
    padding: 6px 10px;
    color: var(--primColor);
    font-weight: 600;
    height: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.liquidationTwo.wizardTheme .multisellerListCard .timeWrp .customFlexBox {
    flex-direction: column;
    gap: 0px;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslMisc .primButton button {
    height: 38px;
    border-radius: 8px;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslDesc {
    margin: 15px;
    padding: 0;
    border: none;
    width: auto;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslDesc .wizDesc {
    display: flex;
    justify-content: center;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslDesc .wizDesc label {
    width: auto;
    font-size: 14px;
    font-weight: 400;
    color: #b6b6b6;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslDesc .wizDesc p {
    font-size: 14px;
    font-weight: 400;
    color: var(--primColor);
    margin: 0;
}

.liquidationTwo.wizardTheme .multisellerListCard .mslDesc h3 {
    color: black;
    font-size: 16px;
    font-weight: 700;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.liquidationTwo.wizardTheme .multisellerListCard .timeWrp .customFlexBox .timeClass {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.liquidationTwo.wizardTheme .multisellerListCard .timeWrp .customFlexBox .actualClass {
    order: 2;
    line-height: 1.2;
}

.liquidationTwo.wizardTheme .multisellerListCard .timeWrp .customFlexBox .actualClass .actualTimer {
    font-size: 14px;
    font-weight: 600;
}

.liquidationTwo.wizardTheme .multisellerListCard .grid-img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
    max-height: 250px;
    border-radius: none;
}

.BidAuction .closeBid {
    background-color: red !important;
    border: 1px solid red !important;
}

.BidAuction .multisellerListCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    box-shadow: 0px 3px 30px #eaeaea;
    padding: 10px;
}

.LotsAuction .multisellerListCard .grid-img-wrap {
    padding: 10px;
    grid-template-columns: 48% 1fr;
    gap: 15px;
    height: 100%;
    display: grid;
}

.LotsAuction .multisellerListCard .grid-img-wrap_multi {
    width: fit-content;
}

.multisellerListCard .listVIewShare {
    position: absolute;
    top: 10px;
    right: 40px;
}

.unsolding {
    font-size: 50px;
}

.gridDiv {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.gridDiv .favoriteCheck {
    height: 30px;
    position: absolute;
    z-index: 67;
    top: 0px;
    right: 0px;
}

.liquidationTwo .share-Icon {
    color: var(--primColor);
    cursor: pointer;
}

.liquidationTwo.primeBids .multisellerListCard {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #000;
}

.liquidationTwo.primeBids .multisellerListCard {
    align-items: center;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .showEnds {
    text-align: center;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap {
    height: 170px;
}

.BidAuction .multisellerListCard .customFlexBox {
    gap: 5px;
}

.BidAuction .multisellerListCard .customFlexBox .timeClass {
    display: inline-flex;
    color: #f10500;
    font-weight: 700;
    font-size: 18px;
}

.BidAuction .multisellerListCard .customFlexBox .actualClass {
    font-size: 14px;
    font-weight: 500;
    display: block;
    width: 100%;
    text-transform: uppercase;
    color: #49454f;
}

.liquidationTwo.primeBids
    .hmActnLstWrpr
    .multisellerListCard
    .grid-img-wrap
    .timerCnt
    .customFlexBox {
    justify-content: center !important;
    align-items: center !important;
}

.liquidationTwo.primeBids .hmActnLstWrpr .multisellerListCard .grid-img-wrap .timerCnt .timeClass {
    text-align: center;
}

.liquidationTwo.primeBids
    .hmActnLstWrpr
    .multisellerListCard
    .grid-img-wrap
    .timerCnt
    .actualClass {
    display: none;
}

.liquidationTwo.primeBids .multisellerListCard .lotImagesGrid img {
    border-radius: 8px;
    border: 1px solid #000;
    transition: box-shadow 0.3s ease;
}

.liquidationTwo.primeBids .multisellerListCard .lotImagesGrid img:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
    margin-top: -2px;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .multi-timer-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background-color: #fde2b5;
    border-radius: 10px;
    border: 1px solid #000;
    box-sizing: border-box;
    min-width: 160px;
    height: 170px;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .multi-timer-card .label {
    color: #555;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 10px;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .multi-timer-card .date {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    background-color: transparent;
}

.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .multi-timer-card .timer {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #711749;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    border: 1px solid #000;
    line-height: 2;
}

.liquidationTwo.primeBids .multisellerListCard .mslMisc .primButton button {
    border-radius: 16px;
    height: 45px;
}

.MultiAuction .multisellerListCard .grid-img-wrap {
    width: 280px;
}

.multisellerListCard .grid-img-wrap .badge.timed {
    background: #e3bb2a;
    color: #333;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.MultiAuction .multisellerListCard .grid-img-wrap .badge.timed {
    background: #f7c62f;
    font-weight: 500;
}

.multisellerListCard .grid-img-wrap .badge.live {
    background: #e33f3f;
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.FoodIndustry .multisellerListCard.buynow .mslMisc .primButton button {
    border-radius: 12px !important;
}

.FoodIndustry .multisellerListCard.auction .mslMisc .primButton button {
    border-radius: 12px !important;
    margin-left: 0px !important;
}

.multisellerListCard.lotCard .grid-img-wrap {
    height: 220px;
    width: 220px;
    cursor: pointer;
    grid-template-columns: 100%;
}

.multisellerListCard.lotCard .grid-img-wrap > span,
.multisellerListCard .grid-img-wrap .lazy-load-image-background {
    width: inherit !important;
    max-height: 200px;
}

.liquidationTwo.primeBids .multisellerListCard.lotCard .grid-img-wrap > span,
.liquidationTwo.primeBids .multisellerListCard .grid-img-wrap .lazy-load-image-background {
    width: 100% !important;
}

.multisellerListCard .grid-img-wrap a {
    display: block;
    height: inherit;
    width: inherit;
}

.LotsAuction .multisellerListCard .grid-img-wrap .qurantHref img {
    height: 105px;
    object-fit: scale-down;
    width: 100%;
}

.LotsAuction .multisellerListCard .grid-img-wrap_multi .qurantHref img {
    height: 85px !important;
    width: 85px !important;
}

.multisellerListCard .grid-img-wrap img {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: 5px;
}

.LotsAuction .multisellerListCard .grid-img-wrap img {
    object-fit: contain !important;
}

.liquidationTwo .multisellerListCard .grid-img-wrap img {
    object-fit: contain !important;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap small {
    display: block;
    font-size: 12px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard {
    border: 1px solid #000;
    padding: 50px 30px;
    border-radius: 16px;
}

.BidAuction .multisellerListCard {
    padding-top: 35px;
    background-color: #fff;
    border-radius: 16px;
    position: relative;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap .showEnds {
    font-size: 22px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap .showEnds small {
    color: #ffffff96;
}

.BidAuction .multisellerListCard .mslMisc .timerCnt {
    margin-bottom: 20px;
    display: block;
}

.BidAuction .multisellerListCard .favoriteCheck {
    height: auto;
    width: -moz-fit-content;
    width: fit-content;
    background: rgb(0, 0, 0, 0%);
}

.BidAuction .favWatchlist .favBtnStyle {
    height: 38px;
    border-radius: 100px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #49454f;
    border: 1px solid #49454f;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    min-width: 130px;
}

.BidAuction .ConditionBox {
    font-size: 14px;
    font-weight: 500;
    color: #49454f;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap .timerCnt .customFlexBox {
    justify-content: flex-start !important;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap .timerCnt .timeClass {
    color: #9bc168;
    font-size: 14px;
    text-align: left;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .grid-img-wrap {
    background-color: #013748;
    border-radius: 10px;
    padding: 15px;
    color: #fff;
    height: auto;
}

.multisellerListCard .grid-img-wrap img.default,
.multisellerListCard .lotImagesGrid img.default {
    object-fit: contain;
    background: #f9f9f9;
}

.multisellerListCard .mslDesc {
    margin: 0 10px 0 20px;
    width: 100%;
    border-right: 1px solid #e8e8e8;
    padding-right: 15px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslDesc {
    border: 0;
    width: calc(100% - 400px);
}

.multisellerListCard .mslDesc .listTitle {
    font-size: 20px;
    font-weight: 500;
    color: #2e2e2e;
    margin: 0 0 15px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.LotsAuction .multisellerListCard .mslDesc .listTitle {
    text-transform: capitalize;
}

.MultiAuction .multisellerListCard .mslDesc .listTitle {
    text-align: left;
    height: auto;
    max-height: 55px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
}

.multisellerListCard .mslDesc .listDesc {
    font-size: 15px;
    font-weight: 400;
    color: #717171;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    max-width: 500px;
}

.multisellerListCard .mslMisc {
    min-width: 250px;
    text-align: center;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslMisc .primButton button {
    text-transform: capitalize;
    color: #65558f;
    background-color: #ece6f0;
    border: 1px solid #ece6f0;
    border-radius: 16px;
    min-height: 56px;
    height: 100%;
    font-size: 18px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslMisc .secButton button {
    text-transform: capitalize;
    color: #fff;
    background-color: #9bc168;
    border: 1px solid #9bc168;
    border-radius: 8px;
    height: 40px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslMisc .secButton button span {
    font-size: 18px;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslMisc .primButton button {
    box-shadow: 0px 1px 3px 0px #0000004d;

    box-shadow: 0px 4px 8px 3px #00000026;
}

.BidAuction .hmActnLstWrpr .multisellerListCard .mslMisc .primButton button span {
    font-size: 18px;
}

.BidAuctionDepot .hmActnLstWrpr .multisellerListCard .mslMisc .secButton button.red-button {
    background: #f10500;
    border-color: #f10500;
}

.liquidationTwo.hyperMicro .multisellerListCard .mslMisc .primButton button {
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    color: var(--primColor);
}

.multisellerListCard .mslMisc .closedText {
    text-align: center;
    display: block;
    font-weight: 500;
    color: #a2a2a2;
}

.MultiSeller .multisellerListCard .mslMisc .closedText {
    margin-bottom: 15px;
}

.FoodIndustry .multisellerListCard .mslMisc .primButton button {
    border-radius: 12px;
}

.multisellerListCard .mslMisc .multiSellerTimerView {
    margin-bottom: 15px;
}

.multisellerListCard .lotImagesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
    gap: 8px;
    margin-top: 10px;
    padding-top: 5px;
    overflow: hidden;
    height: 70px;
}

.multisellerListCard .lotImagesGrid img {
    width: 100%;
    height: 65px;
    object-fit: cover;
    cursor: pointer;
}

.multisellerListCard.lotCard .lotBidInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
}

.BidAuction .multisellerListCard.lotCard .lotBidInfo {
    justify-content: center;
    margin: 0;
}

.multisellerListCard.lotCard .lotBidInfo h6 {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e2e;
}

.BidAuction .multisellerListCard.lotCard .lotBidInfo h6 {
    font-size: 14px;
    font-weight: 500;
    color: #49454f;
    text-transform: uppercase;
}

.BidAuction .multisellerListCard .mslDesc .favBtnStyle {
    margin-top: 0px;
}

.BidAuction .multisellerListCard .mslMisc {
    margin: auto;
    text-align: center;
    height: fit-content;
    min-width: 300px;
}

.multisellerListCard.lotCard .lotBidInfo h6:first-child span {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 0;
    display: block;
    text-align: left;
}

.BidAuction .multisellerListCard.lotCard .lotBidInfo h6 span {
    font-size: 18px;
    font-weight: 700;
    color: #218f23;
    text-align: center;
}

.multisellerListCard.lotCard .lotBidInfo h6.msUnsold {
    width: 100%;
}

.multisellerListCard.lotCard .lotBidInfo h6.msUnsold span {
    text-align: center;
}

.multisellerListCard.lotCard .viewMoreBtn {
    color: #2e2e2e;
    text-transform: initial;
}

.multisellerListCard.lotCard .viewMoreBtn .MuiButton-label {
    font-weight: 600;
}

.multisellerListCard.lotCard .viewMoreBtn .MuiButton-label .material-icons {
    margin-left: 8px;
}

.multisellerListCard .subLotLine .slAct .biddingCnt button {
    width: max-content;
    margin: 0;
}

.multisellerListCard .subLotLine .slAct .biddingCnt.notLogged button {
    width: 100%;
}

/* .multisellerListCard .subLotLine .slAct .biddingCnt .customInput {
    margin-right: 5px;
} */

.MultiSeller .multisellerListCard .subLotLine .slAct .biddingCnt .customInput {
    margin-right: 0;
    margin-bottom: 5px;
}

.MultiSeller .multisellerListCard.lotCard .lotBidInfo h6 {
    margin-bottom: 0;
}

.HeavyEquipment .multisellerListCard {
    box-shadow: 0px 1px 12px -4px #2e2e2e30;
    padding: 10px 12px;
    border-radius: 3px;
}

.HeavyEquipment .subLotLine .slAct .biddingCnt .customInput {
    margin-bottom: 0;
}

.HeavyEquipment .multisellerListCard .mslDesc .listDesc {
    -webkit-line-clamp: 2;
}

.HeavyEquipment .badgeSpan {
    display: inline-flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.HeavyEquipment .multisellerListCard .mslMisc {
    min-width: 200px;
    text-align: center;
}

.HeavyEquipment .AuctionDateRange span {
    font-size: 16px;
    font-weight: 400;
    color: #474747;
}

.HeavyEquipment .AuctionDateRange span:last-child {
    padding-left: 15px;
}

.HeavyEquipment .AuctionDateRange {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 17px;
}

.HeavyEquipment .AuctionDateRange span b {
    font-size: 16px;
    padding-right: 8px;
    font-weight: 500;
}

.HeavyEquipment .multisellerListCard .mslDesc {
    padding-right: 20px;
    /* padding-left: 10px; */
}

.HeavyEquipment .multisellerListCard .mslMisc {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.HeavyEquipment .multisellerListCard .mslMisc .timerCnt > span {
    display: block;
    font-size: 12px;
    color: #474747;
    font-weight: 400;
    margin-bottom: 5px;
}

.HeavyEquipment .auctionListCnt .multisellerListCard .mslMisc .primButton {
    margin-top: auto;
}

.HeavyEquipment .multisellerListCard .mslMisc .primButton button {
    min-height: 40px;
}

.HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
}

.HeavyEquipment .badgeSpan span {
    text-transform: initial;
}

.HeavyEquipment .badgeSpan > span:not(:last-child) {
    background: #f9f9f9;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #474747;
    margin-right: 7px;
}

.HeavyEquipment .multisellerListCard .mslDesc .listTitle {
    margin: 0 0 10px;
    cursor: pointer;
}

.HeavyEquipment .grid-card .badgeSpan span:last-child {
    background: #f9f9f9;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #474747;
    margin-right: 7px;
}

.HeavyEquipment .grid-card .badgeSpan {
    margin-bottom: 23px;
}

.HeavyEquipment .badgeSpan span:last-child {
    font-size: 14px;
    font-weight: 500;
    color: var(--primColor);
    display: flex;
    align-items: center;
    background: unset;
}

.HeavyEquipment .grid-card .AuctionDateRange {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
}

.HeavyEquipment .grid-card .AuctionDateRange span {
    margin-bottom: 15px;
}

/* .HeavyEquipment .multisellerListCard .mslDesc {
    width: fit-content;
} */

.HeavyEquipment .multisellerListCard.lotCard .grid-img-wrap {
    width: 200px;
}

.HeavyEquipment .multisellerListCard .mslMisc .primButton button {
    background: var(--secColor);
    border-color: var(--secColor);
}

.multisellerListCard .lotCount {
    font-size: 16px;
    color: #fff;
    background: rgba(0, 0, 0, 50%);
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    user-select: none;
}

.multisellerListCard .lotCount .material-icons {
    padding-right: 8px;
}

.readMore p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-wrap;
    display: inline;
}

.MultiAuction .hostedBy,
.LiveStock .hostedBy {
    display: none;
}

.MultiAuction .product-media-wrapper .media-body .pv-title {
    text-transform: uppercase;
}

.LiveStock .estimates {
    display: none;
}

.winBtnWrpr .primButton button {
    background: #0cba28 !important;
    border-color: #0cba28 !important;
}

.outbidBtnWrpr .primButton button {
    background: red !important;
    border-color: red !important;
}

.multisellerListCard .lsListInfo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
}

.LiveStock .multisellerListCard .lsListInfo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
}

.multisellerListCard .lsListInfo h6 {
    font-size: 14px;
    text-align: left;
    color: #737373;
    margin: 0;
}

.multisellerListCard .lsListInfo h6 span {
    display: block;
    color: #0e131f;
    font-size: 16px;
}

.multisellerListCard .lsListMisc {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
}

.multisellerListCard .lsListMisc h6 {
    font-size: 16px;
    text-align: left;
    color: #737373;
    margin: 0;
}

.multisellerListCard .lsListMisc h6 span {
    font-size: 16px;
    text-align: left;
    color: #0e131f;
    margin-left: 15px;
}

.tblImgWrpr {
    position: relative;
}

.tblImgWrpr .favoriteCheck {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
}

.Fundraiser .multisellerListCard .mslDesc .listDesc ul {
    padding-left: 20px;
}

.liquidationTwo .multisellerListCard .mslDesc .listTitle {
    color: var(--primColor);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 21px;
}

.BidAuction .multisellerListCard .mslDesc .listTitle {
    font-size: 16px;
    font-weight: 700;
    color: #49454f;
}

.BidAuction .multisellerListCard .quckBidcontain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    padding: 0px;
    margin-top: 10px;
}

.BidAuction .multisellerListCard .quckBidcontain > div:last-child {
    flex-basis: 100%;
}

.buyTableAucTab {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    margin: auto;
}

.Liquidation.cincyCard .buyTableAucTab {
    z-index: 99;
}

.liquidationTwo .multisellerListCard .grid-img-wrap {
    height: 200px;
}

.BidAuction .searchRt .multisellerListCard {
    background: #fff;
    border-radius: 6px;
    padding: 20px 20px;
    padding-top: 35px;
    box-shadow: -4px 10px 10px 0px #00000014;
    position: relative;
    overflow: hidden;
}

.BidAuction .searchRt .multisellerListCard .grid-img-wrap {
    height: 184px;
    width: 184px;
}

.liquidationTwo .multisellerListCard .grid-img-wrap .badge.timed {
    display: none;
}

.liquidationTwo .multisellerListCard .strsEndsDat {
    /* color: var(--primColor); */
    font-weight: 600;
    margin-top: 15px;
}

.liquidationTwo .buyTableAucTab .lctnTxtWrpr {
    padding: 12px 6px;
}

.liquidationTwo .buyTableAucTab .lctnTxtWrpr,
.liquidationTwo .buyTableAucTab .favoriteCheck {
    background-color: #ffffffc2;
    padding: 6px;
}

.text-Yellow {
    color: #ffcc00;
}

/* Responsive */

@media (max-width: 1366px) {
    .BidAuction .hmActnLstWrpr .multisellerListCard {
        padding: 20px 15px;
    }

    .BidAuction .multisellerListCard .mslMisc {
        min-width: 100px;
    }
}

@media (max-width: 1100px) {
    .HeavyEquipment .AuctionDateRange {
        flex-wrap: wrap;
    }

    .HeavyEquipment .AuctionDateRange span:last-child {
        padding-left: 0;
    }

    .HeavyEquipment .multisellerListCard .mslDesc {
        padding-right: 5px;
    }

    .HeavyEquipment .multisellerListCard .mslMisc {
        min-width: 150px;
    }
}

@media (max-width: 1024px) {
    .HeavyEquipment .AuctionDateRange span b,
    .HeavyEquipment .AuctionDateRange span {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .multisellerListCard .mslMisc {
        min-width: 150px;
    }
}

@media (max-width: 990px) {
    .HeavyEquipment .badgeSpan > span:not(:last-child),
    .HeavyEquipment .badgeSpan span {
        font-size: 13px;
    }

    .HeavyEquipment .multisellerListCard .mslDesc .listTitle {
        margin: 0 0 5px;
    }

    .multisellerListCard {
        flex-wrap: wrap;
    }

    .MultiSeller_two .multisellerListCard {
        flex-wrap: nowrap;
    }

    .multisellerListCard .mslDesc {
        width: 60%;
        margin: 0;
        padding: 0 10px;
    }

    .multisellerListCard .mslDesc .listTitle {
        font-size: 18px;
        margin: 0 0 8px;
    }

    .multisellerListCard .mslDesc .listDesc {
        font-size: 14px;
        -webkit-line-clamp: 2;
    }

    .multisellerListCard .lotImagesGrid {
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
        height: auto;
    }

    .multisellerListCard .lotImagesGrid img {
        height: 50px;
    }

    .multisellerListCard .mslMisc {
        /* min-width: 100%; */
        margin-left: 0;
        margin-top: 10px;
    }
}

.grid-card .grid-img-wrap .badge.timed {
    background: #e3bb2a;
    color: #333;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.LotsAuction .lv-bid-details {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    max-width: 680px;
}

.LotsAuction .lv-bid-details .gcTimer,
.LotsAuction .lv-bid-details .galleryPrice {
    width: calc(50% - 20px);
}

.LotsAuction .liquidationBuyCard .lv-bid-btns .primButton button {
    border-radius: 8px;
    height: 40px;
    min-width: 185px;
    padding: 0;
}

.LotsAuction .liquidationBuyCard .lv-bid-btns .gridItemAct {
    display: flex;
    column-gap: 20px;
    margin-top: 20px;
}

.LotsAuction .liquidationBuyCard .mslDesc .MuiAccordion-rounded {
    border-radius: 20px;
    box-shadow: none;
    border: 1px solid #f9f9f9;
    max-width: 680px;
    margin: 20px 0;
}

.LotsAuction .liquidationBuyCard .mslDesc .MuiAccordion-root:before {
    display: none;
}

.LotsAuction .auction-details .multisellerListCard .mslDesc {
    border: none;
}

.LotsAuction .alv-descp {
    font-weight: 600;
}

.BidAuction .multisellerListCard .grid-img-wrap img {
    object-fit: contain;
}

@media (max-width: 850px) {
    .multisellerListCard .mslDesc {
        width: 48% !important;
    }

    .LotsAuction .liquidationBuyCard .lv-bid-btns .primButton button {
        min-width: auto;
    }

    .homeAuctions.mobileView .grid-img-wrap .badge.timed {
        background: #e3bb2a;
        color: #333;
        font-size: 15px;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .grid-card .grid-img-wrap .badge.timed {
        background: #e3bb2a;
        color: #333;
        font-size: 15px;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .BidAuction .multisellerListCard .mslMisc {
        min-width: 90%;
        display: flex;
        justify-content: space-between;
    }

    .BidAuction .multisellerListCard.lotCard .lotBidInfo {
        display: block;
    }
}

@media (max-width: 767px) {
    .multisellerListCard .mslMisc {
        min-width: 150px;
        margin-top: 0;
    }

    .multisellerListCard {
        flex-wrap: nowrap;
    }

    .HeavyEquipment .multisellerListCard {
        flex-wrap: wrap;
    }

    .multisellerListCard .lotImagesGrid img:nth-child(n + 11) {
        display: none;
    }

    .multisellerListCard .lotImagesGrid {
        height: auto;
    }

    .LiveStock .multisellerListCard.lotCard .grid-img-wrap {
        height: 220px;
        width: 100%;
        cursor: pointer;
    }

    .LiveStock .multisellerListCard .mslDesc {
        width: 100% !important;
    }

    .LiveStock .multisellerListCard .mslDesc {
        margin: 15px 0;
    }

    .LiveStock .multisellerListCard .mslDesc .listTitle {
        margin-bottom: 20px;
        -webkit-line-clamp: 2;
        line-height: 29px;
    }

    .LiveStock .multisellerListCard .lsListInfo h6 {
        display: flex;
        align-items: center;
    }

    .LiveStock .multisellerListCard .lsListInfo h6 span {
        width: calc(100% - 78px);
        margin-left: auto;
        margin-top: 0;
    }

    .LiveStock .multisellerListCard .subLotLine {
        max-width: unset;
    }

    .LiveStock .multisellerListCard .subLotLine .slAct {
        justify-content: center;
        width: 100%;
    }

    .MultiSeller_two .multisellerListCard {
        flex-wrap: wrap;
    }

    .liquidationTwo .multisellerListCard .grid-img-wrap {
        width: 100%;
    }

    .multisellerListCard .grid-img-wrap {
        width: 35%;
    }

    .multisellerListCard .grid-img-wrap a,
    .multisellerListCard .grid-img-wrap img {
        width: 100%;
    }

    .multisellerListCard .mslDesc {
        width: 65%;
    }

    .multisellerListCard {
        flex-wrap: wrap;
    }

    .multisellerListCard .mslMisc {
        margin-top: 15px;
        min-width: 100%;
    }

    .LotsAuction .multisellerListCard .mslDesc {
        width: calc(100% - 140px) !important;
        padding: 0;
        border: none;
    }

    .LotsAuction .multisellerListCard .mslDesc .listTitle {
        margin: 0 0 8px;
    }
}

@media (max-width: 674px) {
    .multisellerListCard .mslMisc {
        min-width: 32%;
    }
}

@media (max-width: 600px) {
    .multisellerListCard .mslMisc {
        margin-top: 15px;
        min-width: 100%;
    }

    .BidAuction .multisellerListCard .mslMisc {
        min-width: 100%;
        display: block;
        margin-top: 20px;
    }

    .BidAuction .multisellerListCard .customFlexBox .timeClass {
        margin: 0 auto;
    }

    .BidAuction .searchRt .multisellerListCard {
        justify-content: center;
    }
}

@media (max-width: 525px) {
    .LotsAuction .multisellerListCard .grid-img-wrap .gridItemAct {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .liquidationBuyCard .card.grid-card .grid-img-wrap img,
    .LotsAuction .multisellerListCard .grid-img-wrap img {
        object-fit: contain;
    }

    .MultiSeller_two .multisellerListCard.lotCard .grid-img-wrap {
        width: 100% !important;
    }

    .MultiSeller_two .multisellerListCard .mslDesc {
        width: 100% !important;
    }

    .HeavyEquipment .multisellerListCard .mslDesc {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 0;
    }

    .MultiAuction .multisellerListCard .grid-img-wrap {
        width: 100%;
        background: whitesmoke;
    }

    .MultiAuction .multisellerListCard .grid-img-wrap img {
        object-fit: contain;
    }

    .MultiAuction .multisellerListCard .mslDesc {
        width: 100% !important;
        margin-top: 20px;
        border-right: none;
    }
}

@media (max-width: 503px) {
    .BidAuction .multisellerListCard {
        justify-content: center;
        padding-top: 0px;
    }

    .BidAuction .multisellerListCard .mslDesc {
        width: 100%;
        max-width: 100;
    }

    .BidAuction .multisellerListCard .grid-img-wrap {
        width: 100%;
        max-width: 100%;
    }

    .BidAuction .multisellerListCard .mslImg {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 479px) {
    .LotsAuction .lv-bid-details .gcTimer,
    .LotsAuction .lv-bid-details .galleryPrice {
        width: 100%;
    }

    .MultiSeller .ftrDtlCntnr {
        flex-direction: column;
    }

    .footer-main-standard-social-list {
        text-align: center;
    }

    .multisellerListCard .grid-img-wrap,
    .multisellerListCard .mslDesc,
    .LotsAuction .multisellerListCard .mslDesc {
        width: 100% !important;
    }

    .LotsAuction .multisellerListCard .mslDesc {
        margin-top: 25px;
    }

    .multisellerListCard .mslDesc {
        margin-top: 15px;
    }

    .LotsAuction .liquidationBuyCard .lv-bid-btns .gridItemAct .primButton,
    .LotsAuction .liquidationBuyCard .lv-bid-btns .gridItemAct .lots-bidding-view {
        width: 100%;
    }
}

@media (max-width: 375px) {
    .HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
        font-size: 16px;
    }
}

.tblImgWrpr .soldWrapper {
    background: #00000096;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 59px;
    color: #ffffffa3;
    font-weight: bold;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tblImgWrpr .soldWrapper p {
    margin: 0;
    transform: rotate(324deg);
}
