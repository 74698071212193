.productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
}

.BidAuction .productViewStandalone {
    padding-top: 20px;
    padding-bottom: 20px;
}

.liquidationTwo .checkRotate {
    height: 350px;
}

.liquidationTwo .shwMrBtnWrp {
    flex-wrap: nowrap;
    margin-bottom: 5px;
}

.liquidationTwo .multisellerProductDrawer .mssBidIntDet .mssBidMisc.multisellertwo {
    margin-bottom: 5px;
}

.productViewStandalone .plTitle span {
    display: block;
}

.productViewStandalone .plTitle span:first-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.BidAuction .secdoryBtnFit {
    background-color: #9bc168;
    border: 1px solid #9bc168;
    color: #ffffff;
    border-radius: 16px;
    text-transform: capitalize;
    width: 100%;
    display: block;
    margin-bottom: 16px;
}

.BidAuction .productViewStandalone .pvsCnt .lftCntiner .secdoryBtnFit {
    pointer-events: none;
}

.BidAuctionDepot .secdoryBtnFit.red-button {
    background: #f10500;
    border-color: #f10500;
}

.BidAuction .productViewStandalone .plTitle span.subCat {
    font-size: 13px;
    font-weight: 400;
}

.productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}

.BidAuction .bidAuBidHistory {
    width: 100px;
    text-align: left;
    padding: 0;
}

.BidAuction .dplictInfo span,
.BidAuction .lotNo span {
    font-size: 16px;
    font-weight: 300;
}

.BidAuction .lotNo {
    margin-bottom: 18px;
}

.BidAuction .bidAuctionPv_Paper .innerBaseRtt h5 span {
    font-weight: 300;
    margin-right: 10px;
}

.BidAuction .bidAuctionPv_Paper .innerBaseRtt h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.BidAuction .bidAuctionPv_Paper .innerBaseLft {
    border-right: 1px solid #e7e7e7;
    padding-right: 15px;
    margin-right: 15px;
}

.BidAuction .bidAuctionPv_Paper {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
}

.BidAuction .productViewStandalone .plTitle span:last-child {
    font-size: 22px;
    background: #f2f2f7;
    padding: 15px;
    font-weight: 500;
}

.productViewStandalone .image-gallery {
    width: 520px;
}

.BidAuction .productViewStandalone .image-gallery {
    width: 100%;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: center;
    margin-top: 15px;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productViewStandalone .image-gallery-slide-wrapper.left {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

.productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.BidAuction .productViewStandalone .productInfo h5 {
    margin-bottom: 18px;
    font-size: 16px;
}

.BidAuction .dplictInfo {
    font-size: 16px;
    color: #1e1e1e;
    font-weight: 600;
}

.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.BidAuction .productViewStandalone .productInfo h5 span {
    font-size: 16px;
    font-weight: 300;
    width: auto;
    height: auto;
    padding-right: 15px;
    line-height: normal;
}

.BidAuction .productViewStandalone .productInfo h5 span.badge {
    color: white;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}

.BidAuction .ActionDecription h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    border-bottom: 1px solid #ffcb44;
    width: fit-content;
}

.BidAuction .ActionDecription p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.BidAuction .pvsRight {
    background: #fff;
    padding: 25px 30px;
    border-radius: 16px;
}

.BidAuction .ActionDecription {
    padding: 20px 36px;
    background: #fff;
    border-radius: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.BidAuction .bidSocialLink {
    font-size: 20px;
    text-align: left;
    margin-top: 95px;
    margin-bottom: 35px;
}

.BidAuction .productViewStandalone .pvsCnt .primButton {
    margin-bottom: 15px;
}

.BidAuction .productViewStandalone .pvsCnt {
    gap: 135px;
}

.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.BidAuction .productViewStandalone .mssBidItem {
    flex-direction: column;
}

.BidAuction .productViewStandalone .mssBidItem .slidView {
    display: flex;
    /* grid-template-columns: 1fr 100px 1fr;
    gap: 0px; */
    margin: 0;
}

.BidAuction .productViewStandalone .pvsCnt .addtowatchList {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.BidAuction .addtowatchList button {
    border: 1px solid #013748;
    background: #013748;
    border-radius: 16px;
    width: fit-content;
    padding: 15px 25px;
}

.BidAuction .productViewStandalone .mssBidItem .slidView .centertext,
.BidAuction .productViewStandalone .mssBidItem .productInfo .centertext {
    min-width: fit-content;
    text-align: center;
    margin: 0;
    height: 56px;
    font-size: 16px;
    color: #4c4c4c;
    display: grid;
    place-content: center;
}

.BidAuction .productViewStandalone .mssBidItem .productInfo .centertext {
    height: auto;
}

.BidAuction .productViewStandalone .mssBidItem .productInfo {
    display: flex;
    /* grid-template-columns: 1fr 100px 1fr;
    gap: 0px; */
}

.BidAuction .productViewStandalone .mssBidItem .productInfo .centertext,
.BidAuction .productViewStandalone .mssBidItem .productInfo h5,
.BidAuction .productViewStandalone .mssBidItem .sl-pbids .centertext,
.BidAuction .productViewStandalone .mssBidItem .sl-pbids .sl-pbids-ct {
    padding: 0;
}

.BidAuction .badge {
    color: black;
    padding: 3px 15px;
}

.BidAuction .badge.badge-open {
    background-color: #ffcb44;
}

.BidAuction .biddingCnt .bid-wrapper .primButton button {
    background-color: #9bc168;
    border: 1px solid #9bc168;
    color: #49454f;
    border-radius: 16px;
    text-transform: capitalize;
    height: 56px;
    box-shadow: 0px 5px 5px 0px #0000002e;
}

.BidAuction .biddingCnt .bid-wrapper {
    gap: 15px;
}

.BidAuction .biddingCnt .bid-wrapper > div {
    width: 100%;
}

.BidAuction .quckBidcontain form:nth-child(2) .biddingCnt .bid-wrapper .customInput {
    margin-bottom: 15px;
}

.BidAuction .biddingCnt .bid-wrapper .customInput .MuiTextField-root .MuiTypography-root {
    width: 38px;
    text-align: center;
    height: 56px;
    display: grid;
    place-content: center;
    border-right: 1px solid #ffcb44;
}

.BidAuction
    .biddingCnt
    .bid-wrapper
    .customInput
    .MuiTextField-root
    .MuiOutlinedInput-notchedOutline {
    border-color: #ffcb44;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.BidAuction .biddingCnt .bid-wrapper .customInput .MuiTextField-root .MuiInputBase-root {
    width: 100%;
    min-width: 136px;
    height: 56px;
    padding: 0;
    background-color: #fff;
}

.BidAuction
    .searchContainer
    .biddingCnt
    .bid-wrapper
    .customInput
    .MuiTextField-root
    .MuiInputBase-root {
    width: 100%;
    height: 56px;
    padding: 0;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

.BidAuction .paymentOptions .premiumBtn {
    border: 1px solid #013748;
    background: #013748;
    border-radius: 0;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 16px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    min-width: 65%;
    text-transform: capitalize;
}

.BidAuction .paymentOptions .payRtt .premiumBtn {
    border: 1px solid #013748;
    background: #ffcb44;
    border-radius: 100px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 16px;
    text-align: center;
    color: #000000;
    font-size: 13px;
    min-width: 65%;
    text-transform: capitalize;
}

.BidAuction .paymentOptions .para {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    word-wrap: break-word;
}

.BidAuction .pickupLocation .para {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    word-wrap: break-word;
}

.BidAuction .paymentOptions .payRtt .link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 10px;
}

.BidAuction .paymentOptions .tit {
}

.BidAuction .paymentOptions .tit {
    font-size: 14px;
    font-weight: 400;
}

.BidAuction .paymentOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #ccc;
    padding: 20px 15px;
    grid-gap: 25px;
    gap: 25px;
    border-radius: 8px;
    max-width: 80%;
    margin-bottom: 35px;
}

.BidAuction .pickupLocation p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.BidAuction .pickupLocation h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #ffcb44;
    width: fit-content;
}

.BidAuction .paymentOptions .payLft h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #ffcb44;
    width: fit-content;
}

.BidAuction .pickupLocation .localPickup {
    font-weight: 700;
}

.BidAuction .paymentOptions .payLft {
    border-right: 1px solid #ccc;
    margin-right: 15px;
    padding-right: 15px;
}

.BidAuction .paymentOptions .payRtt {
}

@media (max-width: 1366px) {
    .BidAuction .productViewStandalone .pvsCnt {
        gap: 60px;
    }

    .liquidationTwo .productViewStandalone .sl-pbids.slidView {
        margin-left: 0;
    }

    .liquidationTwo .productViewStandalone .mssBidItem .productInfo {
        margin-right: 10px;
    }

    .liquidationTwo .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }
}

@media (max-width: 990px) {
    .liquidationTwo .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (max-width: 1100px) {
    .BidAuction .productViewStandalone .pvsCnt {
        display: flex;
        flex-direction: column;
    }

    .BidAuction .paymentOptions {
        max-width: 100%;
    }

    .BidAuction .bidSocialLink {
        margin-top: 0px;
    }

    .BidAuction .productViewStandalone .mssBidItem .productInfo {
        margin-top: 12px;
    }
}

@media (max-width: 600px) {
    .BidAuction .productViewStandalone .pvsCnt {
        display: flex;
        flex-direction: column;
    }

    .productViewStandalone .image-gallery {
        width: unset !important;
    }

    .BidAuction .productViewStandalone .image-gallery .checkRotate {
        height: 400px;
    }

    .BidAuction .paymentOptions {
        max-width: 100%;
        grid-template-columns: auto;
    }

    .BidAuction .paymentOptions .payLft {
        padding-right: 0;
        margin-right: 0;
        padding-bottom: 10px;
        border-right: 0;
        border-bottom: 1px solid #ccc;
    }

    .BidAuction .bidAuctionPv_Paper {
        display: block;
    }

    .BidAuction .productViewStandalone .mssBidItem .slidView {
        display: block;
    }

    .BidAuction .biddingCnt .plcNxtBid {
        margin-bottom: unset;
    }

    .BidAuction .bidAuctionPv_Paper .innerBaseLft {
        border-right: unset;
        padding-right: unset;
        margin-right: unset;
    }

    .BidAuction .productViewStandalone .mssBidItem .sl-pbids .sl-pbids-ct {
        max-width: 100%;
    }

    .BidAuction .pvsRight .favoriteCheck {
        margin-top: 18px !important;
        margin-bottom: 40px !important;
    }

    .BidAuction .productViewStandalone {
        margin-top: 0px;
    }

    .BidAuction .ActionDecription {
        padding: 14px 18px;
    }

    .BidAuction .productViewStandalone .mssBidItem .slidView .centertext {
        /* display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin: 14px 0px; */
        display: none;
    }

    .BidAuction .biddingCnt .bid-wrapper {
        margin-top: 20px;
    }

    .liquidationTwo .productViewStandalone .productInfo h5 {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin: 15px 0;
        line-height: 23px;
    }

    .liquidationTwo .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }

    .liquidationTwo .productViewStandalone .biddingCnt .primButton,
    .liquidationTwo .productViewStandalone .biddingCnt .secButton {
        width: 100%;
        max-width: 145px;
    }

    .liquidationTwo .productViewStandalone .pvsBottom .tabBody {
        padding: 10px;
    }
}
